<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4V19V20H3H22V18H4V4H2ZM10.2923 8.79219L6 13.0845V15.9129L10.9994 10.9135L13.7929 13.707L14.5 14.4141L15.2071 13.707L22 6.91412V4.08569L14.5 11.5857L11.7065 8.79219L10.9994 8.08508L10.2923 8.79219Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
