<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Question Mark">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 8C10.8904 8 10 8.89333 10 9.98421H8C8 7.77882 9.79584 6 12 6C14.2042 6 16 7.77882 16 9.98421C16 11.8443 14.7227 13.4007 13 13.8427V15H11V13.1684C11 12.4891 11.5416 12.0029 12.1347 11.964C13.1805 11.8955 14 11.0306 14 9.98421C14 8.89333 13.1096 8 12 8ZM12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5596 12.6904 16 12 16C11.3096 16 10.75 16.5596 10.75 17.25C10.75 17.9404 11.3096 18.5 12 18.5Z"
        :fill="fill"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#71767D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
