<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)' }"
  >
    <path
      d="M17.7519 11.1679C18.3457 11.5638 18.3457 12.4362 17.7519 12.832L8.5547 18.9635C7.89014 19.4066 7 18.9302 7 18.1315V5.8685C7 5.06981 7.89015 4.59342 8.5547 5.03645L17.7519 11.1679Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlayIcon',
  props: {
    size: {
      type: Number,
      default: 24,
    },
    rotate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
